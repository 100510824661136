import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'TC Systems', type: 'sub', active: false, children: [
				{ path: '/pages/aboutus', title: 'About', type: 'link' },
				{ path: '/pages/collection', title: 'Shop collection', type: 'link' },
			]
		},
		{
			title: 'Shopping Systems', type: 'sub', active: false, children: [
				{ path: '/shops/womenswear', title: 'Womenswear', type: 'link' },
				{ path: '/shops/menswear', title: 'Menswear', type: 'link' },
				{ path: '/shops/luxury-goods', title: 'Luxury Goods', type: 'link' },
				{ path: '/shops/food-and-retail', title: 'Food & Retail', type: 'link' },
				{ path: '/shops/computers-and-technology', title: 'Computers & Technololgy', type: 'link' }
			]
		},
		{
			title: 'Integrations', type: 'sub', megaMenu: true, badge: true, badgeText: 'Payment' /*'E-commerce'*/, children: [
				{
					title: 'Payment Integrations', type: 'sub', active: false, children: [
						{ path: '/payment-integrations/payment', title: 'Credit Worthy', type: 'link' },	// Mastercard Integratred Processing - Issuing
						{ path: '/payment-integrations/payment', title: '3DS2', type: 'link' },
						{ path: '/payment-integrations/payment', title: 'Authentication Solutions', type: 'link' },
						{ path: '/payment-integrations/payment', title: 'Payment Installments', type: 'link' },	// installments
					]
				},
				{
					title: 'Payment Fraud Watch', type: 'sub',  active: false, children: [
						{ path: '/payment-integrations/fraud-watch', title: 'Payment Validation', type: 'link' },	// Bill Payment Validator
						{ path: '/payment-integrations/fraud-watch', title: 'Automatic Billing Updater', type: 'link' },
						{ path: '/payment-integrations/fraud-watch', title: 'Fraud Prevention', type: 'link' }, // BIN Lookup
					]
				},
				{
					title: 'Fast Checkouts', type: 'sub', active: false, children: [
						{ path: '/payment-integrations/fast-checkout', title: 'Click to Pay', type: 'link' },
						{ path: '/payment-integrations/fast-checkout', title: 'Ecommerce Notifications', type: 'link' },	// Commercial Event Notifications
						{ path: '/payment-integrations/fast-checkout', title: 'Cardless ATM', type: 'link' },
						{ path: '/payment-integrations/fast-checkout', title: 'EzAccess ID', type: 'link' },	// Mastercard ExAccess
						{ path: '/payment-integrations/fast-checkout', title: 'QR Code Checkout', type: 'link' }	// Mastercard QR
					]
				},
				{
					title: 'Payment Intelligence', type: 'sub', active: false, children: [
						{ path: '/payment-integrations/payment-intelligence', title: 'Consumer Controls', type: 'link' },
						{ path: '/payment-integrations/payment-intelligence', title: 'Biometric Authentication', type: 'link' },
						{ path: '/payment-integrations/payment-intelligence', title: 'Delegated Authentication', type: 'link' },	// Delegated authentication for merchants
					]
				},
				{
					title: 'Payment Secure', type: 'sub', active: false, children: [
						{ path: '/payment-integrations/payment-secure', title: 'Fraud and Loss Alerts', type: 'link' },	// Fraud and Loass Database (FLD)
						{ path: '/payment-integrations/payment-secure', title: 'Loyalty Management', type: 'link' }, // Mastercard Loyalty Management
						{ path: '/payment-integrations/payment-secure', title: 'Integrated Processing', type: 'link' },	// Mastercard Integrated Processing
					]
				},
			]
		},/*
		{
			title: 'Features', type: 'sub', active: false, children: [
				{
					title: 'sidebar', type: 'sub', active: false, children: [
						{ path: '/shop/product/left/sidebar/trim-dress', title: 'left-sidebar', type: 'link' },
						{ path: '/shop/product/right/sidebar/trim-dress', title: 'right-sidebar', type: 'link' },
						{ path: '/shop/product/no/sidebar/trim-dress', title: 'no-sidebar', type: 'link' }
					]
				},
				{ path: '/shop/product/three/column/trim-dress', title: 'three-column', type: 'link' },
				{ path: '/shop/product/four/image/belted-dress', title: 'four-image', type: 'link' },
				{ path: '/shop/product/bundle/trim-dress', title: 'bundle-product', type: 'link' },
				{ path: '/shop/product/image/outside/trim-dress', title: 'image-outside', type: 'link' }
			]
		},*//*
		{
			title: 'pages', type: 'sub', active: false, children: [
				{
					title: 'account', type: 'sub', active: false, children: [
						{ path: '/pages/wishlist', title: 'wishlist', type: 'link' },
						{ path: '/pages/cart', title: 'cart', type: 'link' },
						{ path: '/pages/dashboard', title: 'dashboard', type: 'link' },
						{ path: '/pages/login', title: 'login', type: 'link' },
						{ path: '/pages/register', title: 'register', type: 'link' },
						{ path: '/pages/contact', title: 'contact', type: 'link' },
						{ path: '/pages/forget/password', title: 'forget-password', type: 'link' },
						{ path: '/pages/profile', title: 'profile', type: 'link' },
						{ path: '/pages/checkout', title: 'checkout', type: 'link' },
					]
				},
				{ path: '/pages/aboutus', title: 'about-us', type: 'link' },
				{ path: '/pages/search', title: 'search', type: 'link' },
				{ path: '/pages/typography', title: 'typography', type: 'link', badge: true, badgeText: 'new' },
				{ path: '/pages/review', title: 'review', type: 'link', badge: true, badgeText: 'new' },
				{ path: '/pages/order/success', title: 'order-success', type: 'link' },
					{ 
						title: 'compare', type: 'sub', active: false, children: [
							{ path: '/pages/compare/one', title: 'compare-1', type: 'link' },
							{ path: '/pages/compare/two', title: 'compare-2', type: 'link', badge: true, badgeText: 'new' }
						]
					},
				{ path: '/pages/collection', title: 'collection', type: 'link' },
				{ path: '/pages/lookbook', title: 'lookbook', type: 'link' },
				{ path: '/pages/404', title: '404', type: 'link' },
				{ path: '/pages/comingsoon', title: 'coming-soon', type: 'link', badge: true, badgeText: 'new' },
				{ path: '/pages/faq', title: 'faq', type: 'link' }
			]
		},*/
		{
			title: 'contacts', type: 'link', active: false, children: [
				{ path: '/pages/contact', title: 'sales', type: 'link' },
				{ path: '/pages/contact', title: 'tech support', type: 'link' },
			]
		}
	];

	LEFTMENUITEMS: Menu[] = [
		{
			title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
			  {
				  title: 'mens fashion',  type: 'link', active: false, children: [
					  { path: '/', title: 'sports wear',  type: 'link' },
					  { path: '/', title: 'top',  type: 'link' },
					  { path: '/', title: 'bottom',  type: 'link' },
					  { path: '/', title: 'ethic wear',  type: 'link' },
					  { path: '/', title: 'sports wear',  type: 'link' },
					  { path: '/', title: 'shirts',  type: 'link' },
					  { path: '/', title: 'bottom',  type: 'link' },
					  { path: '/', title: 'ethic wear',  type: 'link' },
					  { path: '/', title: 'sports wear',  type: 'link' }
				  ]
			  },
			  {
				  title: 'women fashion',  type: 'link', active: false, children: [
					  { path: '/', title: 'dresses',  type: 'link' },
					  { path: '/', title: 'skirts',  type: 'link' },
					  { path: '/', title: 'westarn wear',  type: 'link' },
					  { path: '/', title: 'ethic wear',  type: 'link' },
					  { path: '/', title: 'bottom',  type: 'link' },
					  { path: '/', title: 'ethic wear',  type: 'link' },
					  { path: '/', title: 'sports wear',  type: 'link' },
					  { path: '/', title: 'sports wear',  type: 'link' },
					  { path: '/', title: 'bottom wear',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'bags', type: 'sub', active: false, children: [
			  { path: '/', title: 'shopper bags', type: 'link' },
			  { path: '/', title: 'laptop bags', type: 'link' },
			  { path: '/', title: 'clutches', type: 'link' },
			  {
				  path: '/', title: 'purses', type: 'link', active: false, children: [
					  { path: '/', title: 'purses',  type: 'link' },
					  { path: '/', title: 'wallets',  type: 'link' },
					  { path: '/', title: 'leathers',  type: 'link' },
					  { path: '/', title: 'satchels',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
			  { path: '/', title: 'sport shoes', type: 'link' },
			  { path: '/', title: 'formal shoes', type: 'link' },
			  { path: '/', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
			  { path: '/', title: 'fashion jewellery', type: 'link' },
			  { path: '/', title: 'caps and hats', type: 'link' },
			  { path: '/', title: 'precious jewellery', type: 'link' },
			  {
				  path: '/', title: 'more..', type: 'link', active: false, children: [
					  { path: '/', title: 'necklaces',  type: 'link' },
					  { path: '/', title: 'earrings',  type: 'link' },
					  { path: '/', title: 'rings & wrist wear',  type: 'link' },
					  {
						  path: '/', title: 'more...',  type: 'link', active: false, children: [
							  { path: '/', title: 'ties',  type: 'link' },
							  { path: '/', title: 'cufflinks',  type: 'link' },
							  { path: '/', title: 'pockets squares',  type: 'link' },
							  { path: '/', title: 'helmets',  type: 'link' },
							  { path: '/', title: 'scarves',  type: 'link' },
							  {
								  path: '/', title: 'more...',  type: 'link', active: false, children: [
									  { path: '/', title: 'accessory gift sets',  type: 'link' },
									  { path: '/', title: 'travel accessories',  type: 'link' },
									  { path: '/', title: 'phone cases',  type: 'link' }
								  ]
							  },
						]
					  }
				  ]
			  },
			]
		},
		{
			path: '/', title: 'house of design', type: 'link'
		},
		{
			title: 'beauty & personal care', type: 'sub', active: false, children: [
			  { path: '/', title: 'makeup', type: 'link' },
			  { path: '/', title: 'skincare', type: 'link' },
			  { path: '/', title: 'premium beaty', type: 'link' },
			  {
				  path: '/', title: 'more..', type: 'link', active: false, children: [
					  { path: '/', title: 'fragrances',  type: 'link' },
					  { path: '/', title: 'luxury beauty',  type: 'link' },
					  { path: '/', title: 'hair care',  type: 'link' },
					  { path: '/', title: 'tools & brushes',  type: 'link' }
				  ]
			  },
			]
		},
		{
			path: '/', title: 'home & decor', type: 'link'
		},
		{
			path: '/', title: 'kitchen', type: 'link'
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
