import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApplicationConfigurationService {
  private _defaultImageType: string | number = Math.floor(Math.random() * 100) + 1 > 72 ? 'model-2' : '';
  private _imageType = this._defaultImageType;

  constructor(private route: ActivatedRoute) {
    // const id: Observable<string> = 
    route.params.pipe(
        map(p => {
          if (p.promoter.length) {
            this._imageType = p.promoter;
            console.log('via constructor url:', this._imageType);
          }
        })
      );
  }

  public imageType() {    
    // if url then set this.imageType to url else do below
    // this.router.url

    // TODO: not yet working
    this.route.params.pipe(
      map(p => {
        if (p.promoter.length) {
          this._imageType = p.promoter;
          console.log('via url:', this._imageType);
        }
      })
    );
    console.log('DEFAULT MODEL:', this._imageType);
   return this._imageType;
  }
}