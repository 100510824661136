<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From TechCity Systems By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>TechCity offer affordable and effective online shop solutions with continual support. We develop your e-commerce platform to commmitment & satisfaction.</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.linkedin.com/company/techcitysystems"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@techcitysystems"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/people/TechCity-Systems/100090846219833/"><i class="fa fa-facebook" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="https://twitter.com/techcitysystems/"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/techcitysystems/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4><span style="color: #ff4c3b;">Shop</span>ping Systems</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="/shops/womenswear">Womenwear Fashion Shop</a></li>
                                <li><a href="/shops/menswear">Mens Fashion Shop</a></li>
                                <li><a href="/shops/computers-and-technology">Computers & Technology Shop</a></li>
                                <li><a href="/shops/food-and-retail">Food & Retail Shop</a></li>
								<li><a href="/shops/luxury-goods">Luxury Goods Shop</a></li>
                            </ul>
							
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4><span style="color: #ff4c3b;">Shop</span> Integrations</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="/payment-integrations/payment">3DS2 Integration</a></li>
                                <li><a href="/payment-integrations/fraud-watch">Secure Shopping</a></li>
                                <li><a href="/payment-integrations/payment">PrePayment Validation</a></li>
                                <li><a href="/payment-integrations/payment">Payment Installments</a></li>
                                <li><a href="/payment-integrations/payment-secure">Fraud Prevention</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Contacts</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>TechCity Systems, Online Stores</li>
						        <li><i class="fa fa-globe"></i><a href="/pages/contact">Contact form</a></li>
								<li class="tel"><i class="fa fa-phone"></i>UK +00 (0)000 000 0000 <br />EU +00 000 000 000</li>
						        <li><i class="fa fa-envelope-o"></i><a>sales@techcitysystems.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} TechCity Systems powered by TechCity</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->