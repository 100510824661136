import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  public url : any; 

  constructor(private router: Router) {  
    /*
    this.router.events.subscribe((event) => {
      console.log('industries - event instanceof NavigationEnd:', event instanceof NavigationEnd);
      console.log('rouer event:', event);
          if (event instanceof NavigationEnd) {
            this.url = event.url;
          }
    });*/
  }

  ngOnInit(): void {
  }

}
